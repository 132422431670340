import React from "react";
import Layout from "../layout";

import HistoryImg from '../../common/images/about/history.svg'
import Blob from '../../common/images/about/blob.svg'
import FirstTop from '../../common/images/about/header_1.svg'
import SecondTop from '../../common/images/about/header_2.svg'
import ThirdTop from '../../common/images/about/header_3.svg'

const AnimateString = ({name, className}) => {
    return <div className={`d-flex ${className}`}>
        <img src={name} alt="" />
        <img src={name} alt="" />
    </div>
}

const IconTop = ({children}) => {
    return <div className="d-flex">
        <img src={require('../../common/images/about/icon_top.png')} alt="" className="icon-top px-2"/>
        {children}
    </div>
}

const AboutHeader = () => {
    return <div className="w-100 mb-5">
        <div className="h-100 d-flex flex-column justify-content-center pb-5 mw-2000">
            <div className="about-top">
                <AnimateString name={FirstTop} className='animate'/>
            </div>
            <div className="about-top-image w-100 d-flex-center display-9">
                <div className="about-top-image-text py-5">
                    <h1 className="display-1 mb-3 red1-text">О нас</h1>
                    <div>
                        <IconTop>
                            <p>Начиная <strong>с 2007 года</strong>, компания Progmatic Lab (до 2016 года просто Progmatic) осуществляет заказную разработку ПО. </p>
                        </IconTop>
                        <IconTop>
                            <p>За это время специалисты компании <strong>осуществили разработку различных систем и проектов для российских, американских и других международных компаний</strong></p>
                        </IconTop>
                        <IconTop>
                            <p>Помимо стандартной заказной разработки, компания участвовала и продолжает участвовать в ряде opensource проектов и организаций</p>
                        </IconTop>
                    </div>
                </div>
            </div>
            <div className="about-top">
                <AnimateString name={SecondTop} className='animate-reverse'/>
                <AnimateString name={ThirdTop} className='animate'/>
            </div>
        </div>
    </div>
}

const CompanyHistory = () => {
    return <div className="w-100 h-100 mw-2000">
        <div className="row m-big mb-0 company-history">
            <div className="col-xl-6 h-100 p-0">
                <div className="mx-big">
                    <h1 className="display-2 mb-3">История компании</h1>
                    <div className="d-flex px-2 display-9 p-3">
                        <div className="px-3">
                            <img src={require('../../common/images/description/description_2.png')} alt=""  className="icon-size"/>
                        </div>
                        <p>До 2013 года специалисты компании успели поучаствовать в <strong>проектах для Олимпийского Комитета России</strong>, создать <strong>краудсорсинговую платформу Witology</strong> и вместе с фасилитаторами фирмы Витология провести ряд <strong>краудсорсинговых проектов</strong> для таких организаций как <strong>Московская Мэрия</strong>, <strong>РосАтом</strong>, <strong>РосТелеком</strong>, <strong>Сбербанк</strong>.</p>
                    </div>
                </div>
            </div>
            <div className="col-xl-6 h-100">
                <img src={HistoryImg} alt="" style={{zIndex: -1}} className="mx-big"/>
            </div>
        </div>
    </div>
}

const CompanyHistoryTimeline = ({children, m0}) => {
    return <div className="w-100 h-100 mw-2000">
        <div className="mx-big bg-beige p-3">
            <p className="m-0 d-flex">
                <div className="px-3">
                    <img src={require('../../common/images/description/description_2.png')} alt=""  className="icon-size"/>
                </div>
                <div className="w-100">
                    <h5 className="display-9">{children}</h5>
                </div>
            </p>
        </div>
    </div>
}

const PartnerDescription = ({num, name, children, noLine}) => {
    return <div className="w-100 d-flex">
        <div className="d-flex-center flex-column px-5 p-relative">
            <div className="d-flex-center about-partner-circle">
                <h1 className="display-5 m-0">{num}</h1>
            </div>
            <div className={!noLine ? "about-partner-line" : "about-partner-line-none"}/> 
            <img src={Blob} alt="" className="p-absolute about-partner-blob" />
        </div>
        <div className="mb-5">
            <h1 className="display-7 mb-3">{name}</h1>
            <div className="bg-dark mb-3 about-partner-border-b"/>
            <p>{children}</p>
        </div>
    </div>
}

const HisoryPartners = () => {
    return <div className="mw-2000 w-100">
        <div className=" m-big mt-0">
            <div className="py-5">
                <CompanyHistoryTimeline>
                    После 2013 года компания начинает работать с иностранными заказчиками, включая opensource foundations, в основном, в сфере облачных технологий.
                </CompanyHistoryTimeline>
            </div>
            <PartnerDescription num={1} name={'CloudScaling'}>
                <>
                    — участие в разработке системы Open Cloud System, продукта по автоматизации деплоймента OpenStack
                </>
            </PartnerDescription>
            <PartnerDescription num={2} name={'OpenStack Foundation'}>
                <>
                    — разработка проектов совместимости API OpenStack с EC2 API и GCE API<br/>
                    — участие в разработке проекта OpenStack RefStack
                </>
            </PartnerDescription>
            <PartnerDescription num={3} name={'EMC Corp (Dell/EMC)'}>
                <>
                    — разработка и автоматизация деплоймента  и интеграции в различные оркестраторы (Mirantis Fuel Openstack, Canonical JuJu) решения хранения данных ScaleIO<br/>
                    — разработка драйвера ScaleIO для OpenStack cinder и nova<br/>
                    — разработка драйвера Ceph для решения хранения данных CoprHD
                </>
            </PartnerDescription>
            <PartnerDescription num={4} name={'Juniper networks Inc'} noLine>
                <>
                    — участие в разработке SDN Contrail, включая разработку контейнеризации продукта, деплоймента в средах RHOSP, JuJu, Kubernetes, Ansible<br/>
                    — участие в трансформации Contrail в opensource проект Tungsten Fabric и в дальнейшей работе данного коммьюнити<br/>
                    — создание инфраструктуры CI/CD и инструментария разработчика<br/>
                    — интеграция проекта в Akraino Edge Stack и OPNFV<br/>
                    — участие в планировании и разработке Contrail<br/>
                    — участие в разработке новой платформы продуктов Juniper - ATOM
                </>
            </PartnerDescription>
            <div className="pb-5">
                <CompanyHistoryTimeline>
                    В 2017 году компания частично возвращается домой и начинает работать с рядом российских клиентов
                </CompanyHistoryTimeline>
            </div>
            <PartnerDescription num={5} name={'Mail.ru/VK'}>
                <>
                    — участие в разработке и поддержке публичного OpenStack облака, включая создание, оптимизацию и доработку различных новых компонентов и плагинов для расширения инфраструктуры OpenStack<br/>
                    — автоматизация установки приложений в облаке
                </>
            </PartnerDescription>
            <PartnerDescription num={6} name={'АВК-Коммьюникейшнз'}>
                <>
                    — участие в разработке BPA платформы
                </>
            </PartnerDescription>
            <PartnerDescription num={7} name={'Tinkoff'}>
                <>
                    — участие в развертывании новых версий Сontrail
                </>
            </PartnerDescription>
            <PartnerDescription num={8} name={'OpenSDN'}>
                <>
                    — участие в разработке и поддержке наследника продукта Contrail/Tungsten Fabric в новом коммьюнити    
                </>
            </PartnerDescription>
            <PartnerDescription num={9} name={'ОКБ (Объединенное Кредитное Бюро) '} noLine>
                <>
                    — разработка систем Кредитного Бюро<br/>
                    — интеграция с различными внешними источниками, включая ЦБ<br/>
                    — участие в разработке новых схем ЦБ по взаимодействию между кредитными бюро и источниками<br/>
                    — создание различных UI и сбор метрик для Бюро<br/>
                </>
            </PartnerDescription>
            <CompanyHistoryTimeline>
                В настоящий момент компания продолжает расти и поддерживать своих давних и новых клиентов
            </CompanyHistoryTimeline>
        </div>
    </div>
}

export const AboutUs = () => {
    return <Layout>
        <AboutHeader/>
        <CompanyHistory/>
        <HisoryPartners/>
    </Layout>
}